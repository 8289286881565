<template>
  <b-card class="container mx-auto">
    <h1>Compras pendientes de pago</h1>

    <div>
      <p>
        En esta sección podras ver tus compras pendientes de pago creadas por
        tus negocios. En caso de que tengas alguna compra pendiente de pagar,
        puedes pagarla haciendo clic en el botón "Pagar".
      </p>
    </div>

    <payment-methods-modal
      :onClickPaymentMethod="handlePay"
      :acceptedPaymentTypes="acceptedPaymentTypes"
    />

    <main class="py-2">
      <section>
        <orders-filters
          :folio="true"
          :from_store_name="true"
          :store_name="true"
          :created_at="true"
          :delivery_date="true"
          :loading="loading"
          @applyFilters="applyFilters"
          @resetFilters="resetFilters"
        ></orders-filters>
      </section>

      <b-table hover responsive :items="tableItems" :fields="fields">
        <template #cell(store_name)="data"> {{ data.value }} </template>

        <template #cell(created_at)="data">
          {{ data.value | dateNtime2 }}
        </template>

        <template #cell(delivery_date)="data">
          <template v-if="data.value">
            {{ data.value | dateNtime2 }}
          </template>
          <template v-else>
            <span class="text-danger">No asignada</span>
          </template>
        </template>

        <template #cell(total)="data"> ${{ data.value | money }} </template>

        <template #cell(actions)="data">
          <b-button variant="primary" size="sm" @click="onClickPay(data.item)">
            Pagar
          </b-button>
        </template>

        <template #cell(details)="data">
          <div class="text-nowrap">
            <b-button
              :to="{ name: 'order-view', params: { id: data.item.id } }"
              :variant="'primary'"
              size="sm"
            >
              Ver
            </b-button>
          </div>
        </template>
      </b-table>
      <Pagination
        v-if="pendingOrdersPagination.total_pages >= 1"
        :loading="loading"
        :handlePagination="handlePendingOrdersPagination"
        :pagination="pendingOrdersPagination"
        :hideEntriesPerPage="true"
      />
      <div v-else>
        <b-alert show variant="warning" class="p-4">
          No tienes compras pendientes de pago
        </b-alert>
      </div>
    </main>

    <!-- <b-tabs nav-class="mt-2" content-class="mt-1" pills>
      <b-tab title="Pendientes" active> </b-tab>
      <b-tab title="Pagadas">
        <b-table hover responsive :items="orders" :fields="ordersFields">
          <template #cell(created_at)="data">
            {{ data.value | dateNtime2 }}
          </template>

          <template #cell(total)="data"> ${{ data.value | money }} </template>

          <template #cell(payment_type)="data">
            {{
              data.item.order_payments_attributes[0].payment_type | paymentType
            }}
          </template>

          <template #cell(delivery_status)="data">
            <b-badge
              size="sm"
              :variant="
                data.item.delivery_status === 'entregado'
                  ? 'success'
                  : 'warning'
              "
            >
              {{ data.item.delivery_status | deliveryStatus }}
            </b-badge>
          </template>

          <template #cell(details)="data">
            <div class="text-nowrap">
              <b-button
                :to="{ name: 'order-view', params: { id: data.item.id } }"
                :variant="'primary'"
                size="sm"
              >
                Ver
              </b-button>
            </div>
          </template>
        </b-table>

        <Pagination
          v-if="ordersPagination.total_pages >= 1"
          :loading="loading"
          :handlePagination="handleOrdersPagination"
          :pagination="ordersPagination"
          :hideEntriesPerPage="true"
        />
        <div v-else>
          <b-alert show variant="warning" class="p-4">
            No tienes compras pagadas
          </b-alert>
        </div>
      </b-tab>
    </b-tabs> -->

    <codi-payment-modal :loading="loading" :handleCheckout="handleCodiPay" />
    <codi-info-modal />

    <b-modal
      id="codi-qrcode-modal"
      title="CoDi"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      ok-only
    >
      <div class="mb-2">
        <p class="mb-50">
          Abre tu aplicación bancaria, dirígete a la sección de CoDi y escanea
          el código QR. También puedes guardar o hacer una captura de pantalla y
          subirla a tu aplicación bancaria dentro de la sección de CoDi.
        </p>

        <small class="text-muted"> El pago puede tardarse en procesar.</small>
      </div>

      <b-img :src="codiQrcode" fluid />
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions } from "vuex"
import cartMixin from "../../@core/mixins/cartMixin"
import PaymentMethodsModal from "@core/components/checkout/payment-methods-modal.vue"
import OrdersFilters from "@core/components/orders-filters.vue"

import { formatPaymentType } from "@core/utils/filter.js"

import CodiInfoModal from "@/@core/components/WallEateCommerce/CodiInfoModal.vue"
import CodiPaymentModal from "@core/components/checkout/codi-payment-modal.vue"
import { handleAPIErrors } from "../../@core/utils/fetch-utils"
import messagesMixin from "../../@core/mixins/messagesMixin"
import Pagination from "@/@core/components/Pagination.vue"
import debounce from "lodash/debounce"

export default {
  name: "PendingOrdersView",
  mixins: [cartMixin, messagesMixin],
  components: {
    PaymentMethodsModal,
    CodiInfoModal,
    Pagination,
    CodiPaymentModal,
    OrdersFilters,
  },
  data() {
    const establishmentId = this.$route.params.id

    return {
      establishmentId,
      pendingOrders: [],
      pendingOrdersPagination: {
        page: 1,
      },
      tableItems: [],
      selectedOrder: null,
      orders: [],
      ordersPagination: {
        page: 1,
      },
      acceptedPaymentTypes: [],
      codiQrcode: null,
      loading: false,
      fields: [
        {
          key: "folio",
          label: "Folio",
        },
        {
          key: "from_store_name",
          label: "Cliente",
        },
        {
          key: "store_name",
          label: "Vendedor",
        },
        {
          key: "created_at",
          label: "Fecha de compra",
        },
        {
          key: "delivery_date",
          label: "Fecha de entrega",
        },
        {
          key: "total",
          label: "Total",
        },
        // {
        //   key: "actions",
        //   label: "Acciones",
        // },
        {
          key: "details",
          label: "Detalles",
        },
      ],

      ordersFields: [
        {
          key: "folio",
          label: "Folio",
        },
        {
          key: "store.name",
          label: "Cliente",
        },
        {
          key: "created_at",
          label: "Fecha de compra",
        },
        {
          key: "total",
          label: "Total",
        },
        {
          key: "payment_type",
          label: "Tipo de pago",
        },
        {
          key: "delivery_status",
          label: "Estatus de entrega",
        },
        {
          key: "details",
          label: "Detalles",
        },
      ],
      userData: JSON.parse(localStorage.getItem("userData")),
      filters: {
        folio: "",
        from_store_name: "",
        store_name: "",
        created_at: "",
        delivery_date: "",
      },
    }
  },
  mounted() {
    Promise.allSettled([this.getPendingOrders(), this.getOrders()])

    this.ordersConnection = new WebSocket(
      `${process.env.VUE_APP_WSS_URL}/cable?token=${this.userData.token}`
    )

    this.ordersConnection.onmessage = (event) => {
      const message = JSON.parse(event.data)?.message

      if (message?.notification_type == "order_purchased") {
        Promise.allSettled([this.getPendingOrders(), this.getOrders()])
      }
    }

    this.ordersConnection.onopen = () => {
      console.log("Successfully connected to the echo websocket server...")

      this.ordersConnection.send(
        JSON.stringify({
          command: "subscribe",
          identifier: '{"channel":"ApplicationCable::MyChannel"}',
        })
      )
    }
  },
  destroyed() {
    this.ordersConnection.close()
  },
  methods: {
    ...mapActions("pendingOrders", [
      "fetchPendingOrdersMade",
      "updatePendingOrder",
    ]),
    ...mapActions("store", ["fetchStoreAccount"]),
    ...mapActions("orders", ["fetchOrders"]),

    applyFilters(filters) {
      this.filters = { ...filters }
      this.loading = true
      this.debounceFilters()
    },

    debounceFilters: debounce(function () {
      this.getPendingOrders()
    }, 500),

    resetFilters(filters) {
      this.filters = {
        folio: "",
        from_store_name: "",
        store_name: "",
        created_at: "",
        delivery_date: "",
      }
      this.getPendingOrders()
    },

    async handleCodiPay(intent) {
      this.$swal({
        title: "Estas seguro de pagar la compra?",
        text: "Se pagara la compra con el metodo de pago CODI",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, pagar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        reverseButtons: true,
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true
            const res = await this.updatePendingOrder({
              id: this.selectedOrder.id,
              order: {
                payment_type: "codi",
                codi_response_type: intent,
              },
            })

            if (intent === "qrcode") {
              const qrcode = res.payment?.qrcode

              if (qrcode) {
                this.codiQrcode = qrcode
                this.$bvModal.show("codi-qrcode-modal")
              } else {
                this.errorSwal({
                  text: "No se pudo generar el QR Code",
                })
              }
            }
          } catch (e) {
            const errors = handleAPIErrors(e.response.data)

            this.showErrorSwal({
              text: errors[0],
            })
          } finally {
            this.loading = false
          }
        }
      })
    },

    validateTotal(total, paymentType) {
      if (paymentType === "bank_deposit" && total < 10) {
        this.showErrorSwal({
          text: "El monto mínimo para transferencia bancaria es de $10.00",
        })

        return false
      }

      if (paymentType === "codi" && total > 8000) {
        this.showErrorSwal({
          text: "El monto máximo para este método de pago es de  $8,000.00",
        })

        return false
      }

      return true
    },

    async getOrders({ page } = {}) {
      try {
        this.loading = true
        const response = await this.fetchOrders({
          by_order_type: "buy",
          meta: {
            pagination: {
              page: page || 1,
            },
          },
        })
        this.orders = response.data
        this.ordersPagination = response.meta.pagination
      } catch (e) {
        const errors = handleAPIErrors(e.response.data)
        this.errorToast(errors[0])
      } finally {
        this.loading = false
      }
    },

    async getPendingOrders({ page } = {}) {
      try {
        this.loading = true
        const response = await this.fetchPendingOrdersMade({
          by_active_status: true,
          by_payment_status: "pending",
          by_folio: this.filters.folio,
          by_from_store_name: this.filters.from_store_name,
          by_store_name: this.filters.store_name,
          by_created_at: this.filters.created_at,
          by_delivery_date: this.filters.delivery_date,
          meta: {
            pagination: {
              page: page || 1,
            },
          },
        })

        this.tableItems = []
        this.pendingOrders = response.data
        this.pendingOrdersPagination = response.meta.pagination
        this.pendingOrders.forEach((order) => {
          const orderData = order.pending_order
          this.tableItems.push({
            ...orderData,
            ...order,
            folio: order.folio,
            products: order.products,
            from_store_name: order.from_store_name,
            from_store_accepted_payments: order.from_store_accepted_payments,
            storeName: order.products[0].store_name,
          })
        })
      } catch (e) {
        const errors = handleAPIErrors(e.response.data)
        this.errorToast(errors[0])
      } finally {
        this.loading = false
      }
    },

    async handlePendingOrdersPagination({ page }) {
      await this.getPendingOrders({ page })
    },
    async handleOrdersPagination({ page }) {
      await this.getOrders({ page })
    },

    async handlePay(paymentType) {
      const isTotalValid = this.validateTotal(
        this.selectedOrder.total,
        paymentType
      )
      if (!isTotalValid) return

      if (paymentType === "codi") {
        this.$bvModal.show("complete-codi-payment")
        return
      }

      this.$swal({
        title: "Estas seguro de pagar la compra?",
        text: `Se pagara la compra con el metodo de pago ${formatPaymentType(
          paymentType
        )}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, pagar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        reverseButtons: true,
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true
            const res = await this.updatePendingOrder({
              id: this.selectedOrder.id,
              order: {
                payment_type: paymentType,
              },
            })

            this.getPendingOrders()

            await this.handlePayment(paymentType, res)
          } catch (e) {
            const errors = handleAPIErrors(e.response.data)

            this.showErrorSwal({
              text: errors[0],
            })
          } finally {
            this.loading = false
          }
        }
      })
    },

    async onClickPay(order) {
      this.$bvModal.show("payment-methods-modal")
      this.selectedOrder = order
      this.acceptedPaymentTypes = order.from_store_accepted_payments
    },
  },
}
</script>
